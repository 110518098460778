import * as d3 from "d3";

let util = {};

// --------------------------------------------------------------------------- //
// These first few are a hacky and should be refactored away or incorporated into the database.

util.pollGroupLookup = function (surveyTitle) {
	if (surveyTitle.includes("Staff")) {
		return "staff";
	} else if (surveyTitle.includes("Workplace Resilience Survey")) {
		return "employee";
	} else if (surveyTitle.includes("SWAT")) {
		return "swat";
	} else if (surveyTitle.includes("Parent")) {
		return "parent";
	} else {
		return "student";
	}
};

util.pollSurveyLookup = function (surveyTitle) {
	if (surveyTitle === undefined) {
		return "student-resilience";
	} else if (surveyTitle.includes("Staff")) {
		return "school-staff-resilience";
	} else if (surveyTitle.includes("Parent")) {
		return "parent-resilience";
	} else if (surveyTitle.includes("Workplace Resilience Survey")) {
		return "workplace-resilience";
	} else if ("Student Resilience Survey" == surveyTitle) {
		return "student-resilience";
	} else if ("Kindness Survey" == surveyTitle) {
		return "kind-schools";
	} else if (surveyTitle.includes("TOMS Survey")) {
		return "phillips-foundation";
	} else if (surveyTitle.includes("SWAT")) {
		return "school-wellbeing-audit-tool";
	} else if (surveyTitle.includes("No Bullying")) {
		return "no-bullying";
	} else if ("Short Resilience Survey" == surveyTitle) {
		return "short-resilience";
	} else if (surveyTitle.includes("Mi7")) {
		return "IPE-Mi7";
	} else if (surveyTitle.includes("JGI Lower")) {
		return "JGI_NatureWellbeingLower";
	} else if (surveyTitle.includes("JGI Upper")) {
		return "JGI_NatureWellbeingUpper";
	} else if (surveyTitle.includes("JGI School Staff")) {
		return "JGI_NatureWellbeingStaff";
	} else if ("Nature Wellbeing Student" == surveyTitle) {
		return "JGI_NatureWellbeingLower";
	} else if ("Learning Curve Wellbeing Survey" == surveyTitle) {
		return "learning-curve-wellbeing";
	} else {
		return "student-resilience";
	}
};

// --------------------------------------------------------------------------- //
// --------------------------------------------------------------------------- //

Array.prototype.max = function () {
	return this.filter((d) => !isNaN(d)).reduce((max, v) => max >= v ? max : v, -Infinity);
};

Array.prototype.min = function () {
	return this.filter((d) => !isNaN(d)).reduce((min, v) => min <= v ? min : v, Infinity);
};

Number.prototype.round = function (places) {
	return +(Math.round(this + "e+" + places) + "e-" + places);
};

util.getRanges = function (array) {
	var ranges = [],
		rstart,
		rend;
	for (var i = 0; i < array.length; i++) {
		rstart = array[i];
		rend = rstart;
		while (array[i + 1] - array[i] == 1) {
			rend = array[i + 1]; // increment the index if the numbers sequential
			i++;
		}
		ranges.push(rstart == rend ? rstart + "" : rstart + "-" + rend);
	}
	return ranges.join(", ");
};

util.makeLabel = function (array) {
	if (typeof array[0] == "number") {
		return util.getRanges(array);
	} else {
		return array.join(", ");
	}
};

util.minimumNumber = 9;

util.genderLookup = {
	F: "F",
	M: "M",
	l: "F",
	r: "M",
	O: "O",
	T: "O",
	D: "O",
	P: "P",
	Female: "F",
	Male: "M",
	Transgender: "O",
	"Prefer Not To Say": "P",
	Diverse: "O",
	Other: "O",
	"Other/Non-binary": "O",
};

util.genderLookdown = {
	F: "Female",
	M: "Male",
	O: "Other/Non-binary",
	T: "Other/Non-binary",
	D: "Other/Non-binary",
	P: "Prefer Not To Say",
	Female: "Female",
	Male: "Male",
	Transgender: "Other/Non-binary",
	"Prefer Not To Say": "Prefer Not To Say",
	Diverse: "Other/Non-binary",
	Other: "Other/Non-binary",
	"Other/Non-binary": "Other/Non-binary",
};

util.genderShort = {
	F: "Female",
	M: "Male",
	O: "Other/Non-binary",
	T: "Other/Non-binary",
	D: "Other/Non-binary",
	P: "Pref.",
	Female: "Female",
	Male: "Male",
	Transgender: "Other/Non-binary",
	"Prefer Not To Say": "Pref.",
	Diverse: "Other/Non-binary",
	Other: "Other/Non-binary",
	"Other/Non-binary": "Other/Non-binary",
};

util.lookdown = function (a) {
	let rval = [];
	a.forEach(function (g) {
		rval.push(util.genderLookdown[g]);
	});
	return rval;
};

util.agegroupLookdown = (x) => ({
	1 : "-44",
	2: "45+"
}[x]);

util.assert = function (condition, message) {
	if (!condition) {
		throw new Error(message || "Assertion failed");
	}
};

util.interpolate = function (a) {
	// First check that array is of number type.
	if (typeof a[0] != "number") {
		return a;
	} else {
		var values = [],
			min = a.min(),
			max = a.max();

		for (var i = min; i <= max; i++) {
			values.push(i);
		}
		return values;
	}
};

util.unique = function (a) {
	var rval;
	rval = Array.from(a).filter(function (elem, index, self) {
		return index === self.indexOf(elem);
	});
	return rval;
};

util.flat = function (a) {
	return [].concat.apply([], a);
};

util.isParameterDisjoint = function (a1, a2) {
	if ((a1.length == 0) | (a2.length == 0)) {
		return false;
	} else {
		return new Set([...a1, ...a2]).size == new Set(a1).size + new Set(a2).size;
	}
};

util.zeroDot = function (n) {
	if ((n == 0) | isNaN(n)) {
		return ".";
	} else {
		return n;
	}
};

util.falsyZero = function (n) {
	if ((!n) | isNaN(n)) {
		return "0";
	} else {
		return n;
	}
};

util.shortnum = function (n) {
	let len = n.toString().length;
	if (len > 4) {
		return n.toString().substring(0, len - 3) + "k";
	} else {
		return n;
	}
};

util.asNumber = function (array) {
	return array.map((el) => Number(el));
};

util.randomString = function () {
	return Math.random().toString().substr(2, 10);
};

// BASIC VECTOR ARITHMETIC.

util.dot = (a, b) => a.map((x, i) => a[i] * b[i]).reduce((m, n) => m + n);
util.subtract = (a, b) => a.map((x, i) => a[i] - b[i]);
util.divide = (a, b) => a.map((x, i) => a[i] / b[i]);
util.prod = (a, b) => a.map((x, i) => a[i] * b[i]);
util.sum = (a, b) => a.map((x, i) => a[i] + b[i]);
util.pow = (a, b) => a.map((x, i) => a[i] ** b);

// ROOT FINDING

util.root = (f, upperBound, tolerance) => {
	// Find the root of a monotonic function, using the bisection method.

	let left = 0,
		right = upperBound,
		x = upperBound / 2,
		fx = f(x),
		nSteps = 0;

	while ((Math.abs(fx) > tolerance) & (nSteps < 30)) {
		// console.log([left.toPrecision(2), x.toPrecision(2), right.toPrecision(2)], Number(fx.toPrecision(6)));
		if (fx < 0) {
			left = x;
			x = (left + right) / 2;
		} else {
			right = x;
			x = (left + right) / 2;
		}
		fx = f(x);
		nSteps = nSteps + 1;
	}

	return x;
};

// COLOURS

util.colours = {
	r: [267, 86, 55], // Purple
	Male: [267, 86, 55], // Purple
	M: [267, 86, 55], // Purple
	l: [103, 71, 41], // Green
	Female: [103, 71, 41], // Green
	F: [103, 71, 41], // Green
	Diverse: [199, 100, 50],
	Transgender: [199, 100, 50],
	Other: [199, 100, 50],
	D: [199, 100, 50],
	T: [199, 100, 50],
	O: [199, 100, 50],
	"Prefer Not To Say": [19, 18, 33],
	P: [19, 18, 33],
	// "Transgender": 			[14,100,50],
};

util.hexColours = {
	Male: "#822aef", // Purple
	M: "#822aef", // Purple
	Female: "#48b31e", // Green
	F: "#48b31e", // Green
	Diverse: "#00aeff",
	Transgender: "#00aeff",
	Other: "#00aeff",
	D: "#00aeff",
	T: "#00aeff",
	O: "#00aeff",
	"Prefer Not To Say": "#634f45",
	P: "#634f45",
	"diff-15": "#F44336",
	"diff-10": "#FF8000",
	"diff-5": "#FFFF4E",
	"diff-0": "#FFFED0",
	diff0: "#D4FED5",
	diff5: "#A9E7A6",
	diff10: "#64CF63",
	diff15: "#00B500",
};

util.colour = function (d, a) {
	let c = util.colours[d];
	return ["hsla(", c[0], ",", c[1], "%,", c[2], "%,", a, ")"].join("");
};

util.pluralGroup = function (group, mhr) {
	switch (group) {
		case "student":
			return mhr ? "learners" : "students";
		case "swat":
		case "staff":
			return "staff";
		case "employee":
			return "employees";
		case "parent":
			return "parents";
	}
};

const norms_dict = {
	"AU": "Australian Norms (AN)",
	"NZ": "New Zealand Norms (NZN)",
	"Top25": "Australian Norms (AN)",
	"ssF": "Australian Norms (AN)",
};

util.norm_title = (abbr) => norms_dict.hasOwnProperty(abbr) ? norms_dict[abbr].split('(')[0] : "Norms";
util.norm_name = (abbr) => norms_dict.hasOwnProperty(abbr) ? norms_dict[abbr] : "Norms";
util.norm_abbr = (abbr) => norms_dict.hasOwnProperty(abbr) ? norms_dict[abbr].split('(')[1].split(')')[0] : "Norm";

util.counts = function (data, mode) {
	let counts = {
		F: { 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0 },
		M: { 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0 },
		O: { 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0 },
		P: { 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0 },
	};

	// Extract counts.

	if (mode == "core") {
		for (let d of data) {
			counts[d.gender][d.grade] = counts[d.gender][d.grade] + 1;
		}
	} else if (mode == "comp") {
		for (let d of data) {
			counts[d.gender][d.grade] = counts[d.gender][d.grade] + d.nr;
		}
	}

	// Add totals.

	counts["total"] = {
		3: 0,
		4: 0,
		5: 0,
		6: 0,
		7: 0,
		8: 0,
		9: 0,
		10: 0,
		11: 0,
		12: 0,
	};
	for (let gr = 3; gr <= 12; gr++) {
		counts["total"][gr] =
			counts.F[gr] + counts.M[gr] + counts.O[gr] + counts.P[gr];
	}

	for (let gn of ["F", "M", "O", "P", "total"]) {
		let total = 0;
		for (let gr = 3; gr <= 12; gr++) {
			total = total + counts[gn][gr];
		}
		counts[gn]["total"] = total;
	}

	return counts;
};

util.removeIdIfExists = function (id) {
	var el = document.getElementById(id);
	if (el !== null) {
		el.remove();
	}
};

// SVG

util.wrap = function (text, width) {
	text.each(function () {
		var text = d3.select(this),
			words = text.text().split(/\s+/).reverse(),
			word,
			line = [],
			lineNumber = 0,
			lineHeight = 1.1, // ems
			y = text.attr("y"),
			dy = parseFloat(text.attr("dy")),
			tspan = text
				.text(null)
				.append("tspan")
				.attr("x", 0)
				.attr("y", y)
				.attr("dy", dy + "em");
		while ((word = words.pop())) {
			line.push(word);
			tspan.text(line.join(" "));
			if (tspan.node().getComputedTextLength() > width) {
				line.pop();
				tspan.text(line.join(" "));
				line = [word];
				tspan = text
					.append("tspan")
					.attr("x", -0)
					.attr("y", y)
					.attr("dy", ++lineNumber * lineHeight + dy + "em")
					.text(word);
			}
		}
	});
};

util.line = d3
	.line()
	.x(function (d) {
		return d.x;
	})
	.y(function (d) {
		return d.y;
	});

util.monthNames = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];

util.formatDate = (p) => {
	if (p.closeDate == null) {
		return "";
	} else if (p.year < 2019) {
		return `31&nbsp;Dec&nbsp;${p.year}`;
	} else {
		var sd = new Date(p.closeDate);
		return `${sd.getDate()}&nbsp;${
			util.monthNames[sd.getMonth()]
		}&nbsp;${sd.getFullYear()}`;
	}
};

util.culture = {
	21: "Aboriginal",
	22: "Torres Strait Islander",
	1: "Australian",
	9: "Māori",
	6: "Pasifika / Pacific Islander",
	8: "NZ European",
	3: "African",
	4: "Asian",
	5: "European",
	10: "Middle Eastern",
	11: "North American",
	12: "South American",
	7: "Other",
};

util.nationality = {
	1: "Australian",
	2: "Aboriginal or Torres Strait Islander",
	3: "African",
	4: "Asian",
	5: "European",
	6: "Pacific Islander",
	7: "Other",
	8: "NZ European",
	9: "Māori",
};

util.nationality1 = {
	2: "Aboriginal",
	3: "Torres Strait Islander",
	4: "Aboriginal and Torres Strait Islander",
};

util.culture = {
	21: "Aboriginal",
	22: "Torres Strait Islander",
	1: "Australian",
	9: "Māori",
	6: "Pasifika / Pacific Islander",
	8: "NZ European",
	3: "African",
	4: "Asian",
	5: "European",
	10: "Middle Eastern",
	11: "North American",
	12: "South American",
	7: "Other",
	999: "Not Recorded",
};

util.copyrightPdf = (themeCopyrightPdf,currentPage,pageCurrentYear,pageGroup,pageReport) => {
	let copyright = JSON.parse(themeCopyrightPdf.replace(/{{YEAR}}/g, pageCurrentYear));

	return (copyright && copyright.hasOwnProperty('original'))
	?  (
		[
			...(copyright["original"]),  
			...(copyright.hasOwnProperty("specific") 
				? (copyright["specific"]
				.filter(spec => ( 
						!spec.surveys ||
						spec.surveys.includes(pageGroup)
					) && 
					(
						!spec.pages ||
						spec.pages.includes(currentPage)
					) &&
					(
						!spec.reports ||
						spec.reports.includes(pageReport.toLowerCase()) 
					)
				)
				.flatMap(spec => spec.copyright))
				: [])
		]) 
	: copyright;
}

let utilities = util;
export default utilities;
